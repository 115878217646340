<template>
  <div>
    <v-row style="background-color: #fff;">
      <v-col cols="12">
        <v-row>
          <v-col align="center">
            <span class="font_title">โควตามูลค่าคูปองคงเหลือ</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-3">
          <v-col align="center">
            <span class="font_quota mr-2">128</span>
            <span class="font_title"> แต้ม</span>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-4">
          <v-col align="center">
            <v-btn rounded color="primary" outlined width="110px" height="25px">
              <span class="ml-2 font_btn_change">แลกคูปอง</span>
              <v-icon size="20" class="mt-1">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- ****************************************************************************************************** -->
    <v-row style="background-color: #fff;">
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <div class="tag_detail">
                <v-row no-gutters>
                  <v-col cols="12" class="ml-1">
                    <span class="font_type">coupon</span>
                  </v-col>
                </v-row>
              </div>
              <v-img :src="image"></v-img>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col cols="12" align="center">
              <span class="font_title">คูปองร่วมบริจาคอาหาร</span>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="mt-5">
          <v-col cols="12">
            <span class="font_point">100 </span>
            <span class="font_detail_2">แต้ม </span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>ชื่อคูปองที่เปิดรับบริจาค</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2">คูปองอาหารปันสุข</span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>ราคา</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2">100 บาท</span>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <span>รายละเอียดคูปอง</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="font_detail_2" cols="12" v-html="detail_2">
            <!-- <span>{{detail_2}}</span> -->
          </v-col>
        </v-row>

        <v-row>
          <v-col class="line_hr"> </v-col>
        </v-row>
        <v-row class="mt-6">
          <v-col>
            <span>เลือกบุคคลที่ต้องการมอบคูปอง</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-card elevation="0" outlined>
              <v-row class="mt-1">
                <v-col>
                  <span class="font_detail_2">จำนวนคูปอง</span>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col>
                  <span class="font_quota mr-2">8</span>
                  <span class="font_title"> ใบ</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="search"
              dense
              outlined
              placeholder="ค้นหาด้วย @one.th"
              required
              :maxlength="50"
              hide-details
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </v-col>
          <v-col cols="4">
            <v-btn style="color:#1e4d94;" outlined width="110px" height="40px">
              <v-icon size="20">mdi-plus</v-icon>
              <span class="mr-2 font_btn">เพิ่ม</span>
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" align="center">
            <v-btn class="font_btn" style="color:#1e4d94;" outlined width="94%" height="38px">
              <span class="mr-2">ล้าง</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center">
            <v-btn
              class="font_btn"
              style="background: #1E4D94; color:#fff; margin-top:-10px;"
              width="94%"
              height="38px"
              @click="sheet = !sheet"
            >
              <span class="mr-2">ส่งคูปอง</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center" height="200px">
        <v-container width="100%" style="background-color: #ffffff; height: 510px; ">
          <v-row>
            <v-col cols="12">
              <span class="font_title">จำนวนคูปองที่แจก</span>
            </v-col>
            <v-col cols="12" style="margin-top:-10px">
              <span class="font_detail_2">123@one.th</span>
            </v-col>
            <v-col cols="12" align="center" style="margin-top:-10px">
              <v-icon color="primary" :disabled="num == 1" size="37" @click="num_Minus(num)">
                mdi-minus-box-outline</v-icon
              >
              <span class="ml-8 mr-8">{{ num }}</span>
              <v-icon color="primary" size="37" @click="num_Plus(num)">mdi-plus-box-outline</v-icon>
            </v-col>
            <v-col cols="12" style="margin-top:-10px">
              <v-btn style="background: #1E4D94; color:#fff" width="90%" height="40px">
                <span class="font_btn ">ตกลง</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      image: require("../../../assets/image/coupon.png"),
      detail_2:
        "สมาคมอาสาสร้างสุข ชวนท่านร่วมปันอีก 1 มื้อ เพื่อช่วยเหลือผู้ยากไร้ให้รอด <br><br> ยุคโควิด ยังมีคนลำบากอีกหลายชีวิตในหาดใหญ่ ที่เป็น" +
        "กลุ่มเสี่ยงติดเชื้อโควิด 19 และต้องกักตัวอยู่บ้านพร้อมกับความหิวโหยและความขาดแคลน มาร่วมส่งแรงใจให้ 'คนเมืองเดียวกัน' <br><br>" +
        "ได้มีกำลังพอที่จะกักตัวเพื่อลดความเสี่ยงต่อการแพร่เชื้อได้จนครบกำหนด ด้วยการร่วมส่งมอบอาหาร 1 มื้อ หรือมากกว่า ผ่านทางแอปพลิเคชั่น One Chat",
      search: "",
      count: 0,
      checkbox: false,
      num: 1,
      money_set: 50,
      money: 50,
      sheet: false
    };
  },
  watch: {
    // num(val){
    //     console.log(val);
    //     if (this.num == 1) {
    //         this.money = 1 * this.money_set
    //     }
    //     this.money = val * this.money_set
    // }
  },
  computed: {},
  created() {},
  methods: {
    num_Minus(value) {
      if (value > 1) {
        this.num = value - 1;
      }
    },
    num_Plus(value) {
      this.num = value + 1;
    }
  }
};
</script>

<style scoped>
.tag_detail {
  /* width: 154px;
  height: 48px;
  
  background-color: #D32F2F;
  border-radius: 12px 6px 6px 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; */
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 100px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 100px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_detail_2 {
  color: #aeaeae;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.save-btn-div {
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 180px;
  align-items: center;
  box-shadow: 2px 0px 4px rgba(199, 199, 199, 0.4);
  z-index: 1;
  background-color: #ffffff;
}
.font_money {
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
.font_title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #aeaeae;
}
.font_quota {
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  color: #1e4d94;
}
.font_btn_change {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #1e4d94;
}
.font_point {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  line-height: 18px;
  color: #1e4d94;
}
.line_hr {
  margin-left: 3%;
  margin-right: 3%;
  border-bottom: 2px solid #e0e0e0;
}
.font_btn {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}
</style>
