<template>
  <div>
    <i-card
      style="display: flex;background: linear-gradient(93.94deg, #1E4D94 0%, #55B8DC 100%); color: #fff; padding: 0"
    >
      <div class="my-point-left">
        <div class="my-title">
          แต้มของฉัน
        </div>
        <div class="sub-title">
          (Local life)
        </div>
      </div>
      <div class="my-point-divider"></div>
      <div class="my-point-right">
        <div class="my-point-right-inner">
          {{ userPoint.points }}<span style="font-size: 16px; padding-left: 5px">แต้ม</span>
        </div>
      </div>
    </i-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  mounted() {
    this.getPoint();
  },
  computed: {
    ...mapState("user", ["userPoint", "userDonatePoint"])
  },
  methods: {
    ...mapActions("user", ["getPoint"])
  }
};
</script>

<style scoped>
.my-point-left {
  padding: 20px;
  flex: 1;
  position: relative;
  overflow: hidden;
}
.my-point-left .my-title {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.my-point-left .sub-title {
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
}
.my-point-right {
  position: relative;
  flex: 1;
  background: #fff;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.my-point-right-inner {
  position: absolute;
  display: flex;
  align-items: center;
  padding: 20px 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 24px;
  color: #1e4d94;
}
.my-point-divider {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%) rotate(35deg);
  width: 100px;
  height: 200%;
  content: "";
  background: #fff;
}
</style>
