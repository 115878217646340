<template>
  <div>
    <v-row style="background-color: #fff">
      <v-col cols="12">
        <v-card elevation="0">
          <v-row justify="center" align="center" no-gutters>
            <v-col cols="12">
              <v-img :src="coupon.couponDetail.couponImagePath"></v-img>
            </v-col>
          </v-row>
        </v-card>
        <v-row class="mt-1">
          <v-col cols="12">
            <span>ชื่อคูปองที่เปิดรับบริจาค</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2">{{ coupon.couponDetail.couponDetail.couponName }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span>ราคา</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <span class="font_detail_2">{{ coupon.price }} บาท</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span>รายละเอียดคูปอง</span>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="font_detail_2" cols="12">
            {{ coupon.couponDetail.couponDetail.couponDescription }}
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col>
            <p>ลงนามบริจาค</p>
          </v-col>
          <v-col align="right">
            <p class="my-0" style="color: #aeaeae; font-size: 14px">{{ count }}/50</p>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              dense
              outlined
              placeholder="ระบุชื่อผู้บริจาค"
              required
              :maxlength="50"
              hide-details
              :disabled="checkbox == true"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 150px">
          <v-col cols="12">
            <div class="d-flex justify-center" style="margin-top: -10px">
              <v-checkbox class="my-0" v-model="checkbox"></v-checkbox>
              <p class="mx-1 my-0 mt-1 font_detail_2">ไม่ประสงค์ลงนาม</p>
            </div>
          </v-col>
        </v-row>
        <v-row class="save-btn-div" no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col cols="12" align="center">
                <span class="font_detail_2" style="margin-top: -5px">ระบุจำนวนคูปอง</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="mt-4">
              <v-col align="center">
                <v-icon color="primary" :disabled="num == 1" size="37" @click="num_Minus(num)">
                  mdi-minus-box-outline</v-icon
                >
                <span class="ml-8 mr-8">{{ num }}</span>
                <v-icon color="primary" size="37" @click="num_Plus(num)"
                  >mdi-plus-box-outline</v-icon
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center">
                <v-btn style="background: #1e4d94" width="90%" height="40px" @click="pay">
                  <v-row no-gutters>
                    <v-col align="left">
                      <span class="font_money">ชำระเงิน</span>
                    </v-col>
                    <v-col align="right">
                      <span class="font_money">{{ totalPrice }}</span>
                      <span class="font_money">บาท</span>
                    </v-col>
                  </v-row>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import axios from "clients/axios";
export default {
  components: {},
  data() {
    return {
      image: require("../../../assets/image/coupon.png"),
      detail_2:
        "สมาคมอาสาสร้างสุข ชวนท่านร่วมปันอีก 1 มื้อ เพื่อช่วยเหลือผู้ยากไร้ให้รอด <br><br> ยุคโควิด ยังมีคนลำบากอีกหลายชีวิตในหาดใหญ่ ที่เป็น" +
        "กลุ่มเสี่ยงติดเชื้อโควิด 19 และต้องกักตัวอยู่บ้านพร้อมกับความหิวโหยและความขาดแคลน มาร่วมส่งแรงใจให้ 'คนเมืองเดียวกัน' <br><br>" +
        "ได้มีกำลังพอที่จะกักตัวเพื่อลดความเสี่ยงต่อการแพร่เชื้อได้จนครบกำหนด ด้วยการร่วมส่งมอบอาหาร 1 มื้อ หรือมากกว่า ผ่านทางแอปพลิเคชั่น One Chat",
      name: "",
      count: 0,
      checkbox: false,
      num: 1,
      money_set: 50
    };
  },
  watch: {
    name(val) {
      console.log(val);
      if (val.length != 0) {
        this.count = val.length;
      }
      if (val == null || val == "") {
        this.count = 0;
      }
    }
  },
  computed: {
    ...mapState("coupon", ["coupon"]),
    ...mapState("user", ["userAccount"]),
    ...mapState("agency", ["agency"]),
    totalPrice() {
      return this.coupon.price * this.num;
    }
  },
  async mounted() {
    if (!this.coupon) {
      this.$router.push({ name: "detail_donate" });
    }
    await this.getAcount();
    this.name = `${this.userAccount.account_title_th}${this.userAccount.first_name_th} ${this.userAccount.last_name_th}`;
  },
  methods: {
    ...mapActions("user", ["getAcount"]),
    num_Minus(value) {
      if (value > 1) {
        this.num = value - 1;
        this.money = this.money - this.money_set;
      }
    },
    num_Plus(value) {
      this.num = value + 1;
      this.money = this.money + this.money_set;
    },
    async pay() {
      const body = {
        paymentAmount: this.totalPrice,
        donorId: localStorage.getItem("account_id"),
        donorOrderId: localStorage.getItem("account_id") + new Date().getTime(),
        agencyProjectId: this.$route.params.id,
        donorName: this.checkbox ? "" : this.name,
        showDonorName: !this.checkbox,
        couponId: this.coupon.couponId,
        couponName: this.coupon.couponName,
        numOfCoupon: this.num
      };
      const res = await axios.post(`/donate-coupon/payment`, body);
      if (res.data.data.url) {
        window.location.href = res.data.data.url;
      }
    }
  }
};
</script>

<style scoped>
.tag_detail {
  /* width: 154px;
  height: 48px;
  
  background-color: #D32F2F;
  border-radius: 12px 6px 6px 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1; */
  width: 0;
  height: 0;
  border-left: 0px solid #d32f2f;
  border-right: 100px solid transparent;
  border-bottom: 0px solid transparent;
  border-top: 100px solid #d32f2f;
  position: absolute;
  z-index: 2;
}
.font_detail_2 {
  color: #aeaeae;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}
.save-btn-div {
  position: fixed;
  bottom: 0%;
  left: 0%;
  width: 100%;
  height: 180px;
  align-items: center;
  box-shadow: 2px 0px 4px rgba(199, 199, 199, 0.4);
  z-index: 1;
  background-color: #ffffff;
}
.font_money {
  color: #fff;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.font_type {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 2;
  margin-top: -70px;
}
</style>
