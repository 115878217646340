<template>
  <div class="card-list-container">
    <template>
      <v-card class="mx-auto card-coupon-send" max-width="344">
        <v-list-item three-line class="pa-0">
          <v-list-item-content class="pa-0">
            <v-img class="rounded-xl" height="250" :src="get_coupon.detail.image"></v-img>
            <v-card-title
              class="pa-0 pt-3"
              style="font-size: 16px; text-align: -webkit-center; 
                display: grid !important; color: rgb(3 60 146); font-weight: bold;"
            >
              {{ get_coupon.couponName }}
            </v-card-title>
            <v-card-title
              class="pa-0"
              style="font-size: 12px; text-align: -webkit-center; display: grid !important;"
            >
              <div class="d-flex justify-space-between px-2">
                <div>
                  ส่วนลด
                  {{ get_coupon.detail.benefits.discount }} บาท
                </div>
                <div>ใช้ได้ถึง {{ dataTimeFormat(get_coupon.detail.expireTime) }}</div>
              </div>
            </v-card-title>
          </v-list-item-content>
        </v-list-item>
        <v-checkbox v-show="get_coupon.status == 'waiting'" v-model="checkbox">
          <template v-slot:label>
            <div
              style="font-family: Prompt;
                                font-style: normal;
                                font-weight: normal;
                              font-size: 14px;
                                line-height: 22px; color: #333333;"
            >
              ยอมรับเงื่อนไขการรับคูปอง
            </div>
          </template>
        </v-checkbox>
        <v-card-actions>
          <i-button
            v-show="get_coupon.status == 'waiting'"
            outlined
            rounded
            text
            style="height: 32px"
            :disabled="!checkbox"
            @click="postCoupon()"
            >รับคูปอง</i-button
          >
        </v-card-actions>
        <div v-show="get_coupon.status == 'approve'" class="text-center blue--text">
          คูปองถูกรับไปแล้ว
        </div>
        <div v-show="get_coupon.status == 'reject'" class="text-center red--text">
          คูปองถูกยกเลิกแล้ว
        </div>
        <!-- <v-btn disabled outlined rounded text style="height: 32px" @click="1">รับคูปอง</v-btn> -->
      </v-card>
    </template>
  </div>
</template>

<script>
import axios from "clients/axios";
import moment from "moment";

export default {
  data: () => ({
    get_coupon: "",
    checkbox: null
  }),
  mounted() {
    this.getCoupon();
  },
  methods: {
    getCoupon() {
      axios.get("coupon/getcoupon/" + this.$route.params.id).then(response => {
        this.get_coupon = response.data.data;
      });
    },
    postCoupon() {
      axios.post("coupon/approvecoupon/" + this.$route.params.id, {});
      this.$dialog({
        showCancel: false,
        type: "success",
        desc: "รับคูปองเรียบร้อยแล้ว"
      }).then(() => {
        this.getCoupon();
      });
    },
    dataTimeFormat(value) {
      return moment(value).format("DD/MM/YY");
    }
  }
};
</script>

<style scoped>
.card-coupon-send {
  background: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 18px !important;
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: none !important;
}
</style>
