import actions from "./actions";
import mutations from "./mutations";

const state = {
    coupons: [],
    coupon: null
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
  };