import Home from "views/home/index.vue";
import Test from "views/home/test.vue";
import Agency from "views/agency/index.vue";
import Donation from "views/donation/index.vue";
import Coupon from "views/coupon/index.vue";
import Login from "views/login/index.vue";
import Donatesuccess from "views/donatesuccess/index.vue";

import DetailDonateCoupon from "views/detail_donate/index.vue";
import detail from "views/detail_donate/modules/detail.vue";

import manage_coupon from "views/manage_coupon/index.vue";
import manage_detail from "views/manage_coupon/modules/detail.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/test",
    name: "home",
    component: Test,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/agency/:id",
    name: "agency",
    component: Agency
  },
  
  {
    path: "/donation/:id/:type",
    name: "donation",
    component: Donation
  },
  
  {
    path: "/coupon/:id",
    name: "coupon",
    component: Coupon,
    meta: { background: "#f5f5f5" }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { background: "#f5f5f5", layout: "basic" }
  },
  {
    path: "/donatesuccess",
    name: "login",
    component: Donatesuccess,
    meta: { background: "#f5f5f5", layout: "basic" }
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  //   meta: { layout: "basic-layout" }
  // }
  {
    path: "/donationcoupon/:id",
    name: "detail_donate",
    component: DetailDonateCoupon,
    meta: { background: "#f5f5f5", title: "เลือกคูปอง" }
  },
  {
    path: "/donationcoupon/:id/detail",
    name: "donatecoupondetail",
    component: detail,
    meta: { background: "#f5f5f5", title: "ร่วมบริจาค" }
  },

  {
    path: "/manage_coupon",
    name: "manage_coupon",
    component: manage_coupon,
    meta: { background: "#f5f5f5", title: "จัดการคูปอง" }
  },
  {
    path: "/manage_detail",
    name: "manage_detail",
    component: manage_detail,
    meta: { background: "#f5f5f5", title: "รายละเอียด" }
  }
];

export default routes;
