<template>
  <div class="card-list-container">
    <template v-if="items.length > 0">
      <card-item
        v-for="(item, i) in items"
        :key="i"
        :title="item.name"
        :desc="item.details"
        :image="item.picture"
        style="margin-bottom: 10px;"
      >
        <template v-slot:action>
          <i-button style="height: 32px" @click="handleClick(item[itemKey])">ร่วมบริจาค</i-button>
        </template>
      </card-item>
    </template>
    <i-card v-else style="display:flex; justify-content: center">ไม่พบข้อมูล</i-card>
  </div>
</template>

<script>
import CardItem from "./card-item";
export default {
  props: {
    items: {
      default: () => []
    },
    itemKey: {
      default: () => "id"
    },
    path: {
      default: () => ""
    }
  },
  components: {
    CardItem
  },
  methods: {
    handleClick(key) {
      this.$router.push(`/${this.path}/${key}`);
    }
  }
};
</script>

<style scoped>
.card-list-container {
  padding: 20px 0 0 0;
}
</style>
