import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import agency from "./modules/agency";
import user from "./modules/user";
import coupon from "./modules/coupon";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    agency,
    user,
    coupon
  }
});
