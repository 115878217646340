<template>
  <div>
    <MyPoint />
    <br />
    <div class="home-title">หน่วยงานและมูลนิธิ</div>
    <i-text-field v-model="search" label="ค้นหาหน่วยงานและมูลนิธิ" hide-details></i-text-field>
    <CardList path="agency" :items="list" />
  </div>
</template>

<script>
// @ is an alias to /src
import MyPoint from "./modules/my-point";
import CardList from "components/card/card-list.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "home",
  components: {
    MyPoint,
    CardList
  },
  data: () => ({
    search: "",
    list: []
  }),
  watch: {
    search(val) {
      this.list = [...this.agencies].filter(
        a => (a.name && a.name.includes(val)) || (a.agencyName && a.agencyName.includes(val))
      );
    }
  },
  async mounted() {
    await this.getAgencies();
    this.list = [...this.agencies];
  },
  computed: {
    ...mapState("agency", ["isFetching", "agencies"])
  },
  methods: {
    ...mapActions("agency", ["getAgencies"])
  }
};
</script>

<style scoped>
.home-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 5px;
}
</style>
