<template>
  <div>
    <div>
      <CardItem
        :title="agency.name"
        :desc="agency.details"
        :image="agency.picture"
        style="padding: 1px"
      />
      <br />
      <PointDetail :type="donatePointType" v-if="!donatePointType" />
      <br />
      <DonationForm :type="donatePointType" />
    </div>
  </div>
</template>

<script>
import CardItem from "components/card/card-item.vue";
import PointDetail from "./modules/point-detail";
import DonationForm from "./modules/donation-form";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    CardItem,
    PointDetail,
    DonationForm
  },
  data: () => ({
    image: require("assets/image/preview.png"),
    pointType: "point"
  }),
  async mounted() {
    await this.getAgency(this.$route.params.id);
    if (this.$route.params.type == "donatepoint") {
      this.pointType = "donatepoint";
    } 
  },
  computed: {
    ...mapState("agency", ["isFetching", "agency"]),
    donatePointType() {
      return this.pointType == "donatepoint";
    }
  },
  methods: {
    ...mapActions("agency", ["getAgency"])
  }
};
</script>

<style scoped></style>
