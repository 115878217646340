import axios from "axios";
import { app } from "../main.js";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL_API;

const axiosApiInstance = axios;

axiosApiInstance.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };
    }
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    console.log("IN client error ");
    return Promise.reject(error);
  }
);

// Response interceptor for API calls ทำหลังจากได้ผลรับ
axiosApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async function(error) {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      !originalRequest._retry &&
      localStorage.getItem("refresh_token")
    ) {
      console.log(401);
      originalRequest._retry = true;
      const access_token = await refreshAccessToken();
      // const access_token = "hlleoooooo";
      // console.log("access_token=>" + access_token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + access_token;
      return axiosApiInstance(originalRequest);
      // localStorage.clear();
    } else {
      console.log(error.response.data.message);

      app.$swal.fire({
        icon: "warning",
        width: 500,
        title: error.response.data.message,
        showConfirmButton: false,
        timer: 4000,
        showCloseButton: true
      });
    }
    return Promise.reject(error);
  }
);

export const instance = config => {
  axiosApiInstance.create(config);
};

export default {
  axiosApiInstance,
  get: (url, config = {}) => {
    return axiosApiInstance({ url, method: "get", ...config });
  },
  post: (url, data, config = {}) => {
    return axiosApiInstance({ url, method: "post", data, ...config });
  },
  put: (url, data, config = {}) => {
    return axiosApiInstance({ url, method: "put", data, ...config });
  }
};

async function refreshAccessToken() {
  try {
    const response = await axios.post(process.env.VUE_APP_BASE_URL_API + "auth/refreshtoken", {
      refreshToken: localStorage.getItem("refresh_token")
    });

    localStorage.setItem("access_token", response.data.access_token);
    localStorage.setItem("account_id", response.data.account_id);
    localStorage.setItem("username", response.data.username);
    localStorage.setItem("refresh_token", response.data.refresh_token);

    return response.data.access_token;
  } catch {
    // localStorage.clear();
    app.$router.push("/login");
  }
}
