<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="290">
      <v-card style="border-radius: 16px;">
        <General :type="type" :title="title" :desc="desc" :image="image" :icon="icon" />
        <v-card-actions style="display: flex; padding: 20px 30px">
          <div style="width: 100%;display: flex;justify-content: space-around;">
            <v-btn v-if="showCancel" color="#FF8576" outlined @click="dialog = false">
              {{ localCancelLabel }}
            </v-btn>
            <i-button @click="handleOk" :loading="loading">
              {{ localOkLabel }}
            </i-button>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import General from "./general";
export default {
  components: {
    General
  },
  data() {
    return {
      dialog: false,
      title: null,
      desc: null,
      cancelLabel: null,
      okLabel: null,
      image: null,
      showCancel: true,
      loading: false,
      type: "general",
      icon: null
    };
  },
  computed: {
    localCancelLabel() {
      return this.cancelLabel || "cancel";
    },
    localOkLabel() {
      return this.okLabel || "OK";
    }
  },
  methods: {
    async handleOk() {
      this.dialog = false;
    }
  }
};
</script>
