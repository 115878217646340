<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <div class="donation-title">จำนวนที่ต้องการบริจาค</div>
      <i-text-field
        v-model="amount"
        label="ระบุจำนวนที่ต้องการบริจาค"
        type="number"
        :rules="amountRules"
        required
      ></i-text-field>
      <div class="donation-title">ลงนามบริจาค</div>
      <i-text-field
        v-model="name"
        label="ระบุชื่อผู้บริจาค"
        :rules="nameRules"
        required
        :disabled="checkbox"
      ></i-text-field>
      <v-checkbox
        class="i-checkbox"
        v-model="checkbox"
        label="ไม่ประสงค์ออกนาม"
        style="margin-top: 0"
      ></v-checkbox>
      <div class="detail-action">
        <i-button style="height: 45px" @click="handleClick">บริจาค</i-button>
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
export default {
  props: {
    type: {
      default: () => false
    }
  },
  data: () => ({
    valid: true,
    name: null,
    amount: null,
    checkbox: false
  }),
  async mounted() {
    this.getPoint();
    await this.getAcount();
    this.name = `${this.userAccount.account_title_th}${this.userAccount.first_name_th} ${this.userAccount.last_name_th}`;
  },
  computed: {
    ...mapState("user", ["userAccount", "userPoint", "userDonatePoint"]),
    ...mapState("agency", ["agency"]),
    point() {
      return this.type ? this.userDonatePoint.points : this.userPoint.points;
    },
    pointType() {
      return this.type ? "donatepoint" : "point";
    },
    amountRules() {
      return [
        (v) => !!v || "กรุณากรอกจำนวน",
        (v) => v > 0 || "กรุณากรอกจำนวนมากกว่า 0 แต้ม",
        (v) => {
          if (this.type && Number(v) < 20) {
            return "บริจาคขั้นต่ำ 20 บาท";
          } else if (!this.type && Number(v) > Number(this.point)) {
            return "แต้มของคุณไม่เพียงพอ";
          }
          return true;
        }
        //   v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ];
    },
    nameRules() {
      if (this.checkbox) {
        return [];
      }
      return [
        (v) => !!v || "กรุณากรอกชื่อ"
        //   v => (v && v.length <= 10) || "Name must be less than 10 characters"
      ];
    }
  },
  methods: {
    ...mapActions("user", ["getAcount", "getPoint", "donate"]),
    async handleClick() {
      if (!this.type) {
        if (this.$refs.form.validate()) {
          const body = {
            agencyId: this.agency.id,
            donorId: localStorage.getItem("account_id"),
            donorName: this.checkbox ? "" : this.name,
            point: this.amount,
            pointType: this.pointType,
            showDonorName: this.checkbox
          };

          console.log(body);

          const res = await this.donate(body);

          if (res.status) {
            this.$dialog({
              showCancel: false,
              okLabel: "กลับหน้าหลัก",
              image: require("assets/thank-icon.svg"),
              desc: "ขอบคุณที่ร่วมบริจาคในครั้งนี้"
            }).then(() => {
              this.$router.push("/");
            });
          } else {
            this.$dialog({
              showCancel: false,
              okLabel: "กลับหน้าหลัก",
              image: require("assets/thank-icon.svg"),
              desc: "บริจาคไม่สำเร็จ"
            }).then(() => {
              this.$router.push("/");
            });
          }
        }
      } else {
        if (this.$refs.form.validate()) {
          const body = {
            paymentAmount: this.amount,
            agencyId: this.agency.id,
            donorId: localStorage.getItem("account_id"),
            donorOrderId: localStorage.getItem("account_id") + new Date().getTime(),
            donorName: this.checkbox ? "" : this.name,
            pointType: this.pointType,
            showDonorName: this.checkbox,
            type: "in"
          };
          const res = await axios.post(`payment`, body);
          if (res.data.body.url) {
            window.location.href = res.data.body.url;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.donation-title {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  margin-bottom: 10px;
}
.agency-dialog-head {
  bottom: 0 !important;
  position: absolute !important;
  width: 100%;
}
.agency-dialog-title {
  font-family: Prompt;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #757575;
}
.bottom-dialog-hey {
  height: 0%;
  margin-bottom: 0;
  padding: 0px;
  margin: 0px;
  align-self: flex-end;
}
.v-dialog {
  position: absolute;
  bottom: 0;
}
</style>

<style>
.i-checkbox.v-input--checkbox .v-input__slot .v-icon {
  color: #c7c7c7;
}
.i-checkbox.v-input--checkbox .v-input__slot .v-label {
  color: #c7c7c7;
}
</style>
