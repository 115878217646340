<template>
  <div id="app">
    <v-app style="font-family: 'Prompt', sans-serif !important">
      <component :is="layout">
        <router-view />
        <DialogPDPA
          v-model="localDialog"
          @handel-accept="accept"
          :data_pdpa="data_pdpa"
          @handel-reject="rejectConsent"
        />
      </component>
    </v-app>
  </div>
</template>

<script>
import DialogPDPA from "../src/views/consent_pdpa/DialogPDPA.vue";
import axios from "clients/axios";
const baseUrl = process.env.VUE_APP_BASE_URL_API;
export default {
  components: {
    DialogPDPA
  },
  data: () => ({
    localDialog: null,
    data_pdpa: "",
    data_approved: ""
  }),
  computed: {
    layout() {
      return this.$route.meta.layout || "main-layout";
    }
  },
  created() {
    this.checkUserPdpa();
  },
  methods: {
    // handleClickDialog() {
    //   this.localDialog = true;
    // },
    accept() {
      this.approvePDPA();
    },
    async approvePDPA() {
      const userId = localStorage.getItem("account_id");
      // console.log("approve", userId);
      try {
        const res = await axios.get(`${baseUrl}consent/approve?userId=${userId}&service=donation`);
        const { data } = res;
        console.log(data.message);
        this.localDialog = false;
        this.checkUserPdpa();
      } catch (error) {
        console.log(error);
      }
    },
    async rejectConsent() {
      const userId = localStorage.getItem("account_id");
      // console.log("reject", userId);
      try {
        const res = await axios.get(`${baseUrl}consent/reject?userId=${userId}&service=donation`);
        const { data } = res;
        console.log(data.message);
        this.checkUserPdpa();
      } catch (error) {
        console.log(error);
      }
    },
    //  check pdpa
    async checkUserPdpa() {
      const userId = localStorage.getItem("account_id");
      // const userId = "2648284886485780";
      // console.log(userId);
      try {
        const res = await axios.get(`${baseUrl}consent?userId=${userId}&service=donation`);
        const { data } = res;
        this.data_pdpa = data.data;
        this.data_approved = data.message;
        if (this.data_approved != "approved") {
          this.localDialog = true;
        } else {
          // console.log("ผ่าน");
          // this.checkPDPA();
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style>
html {
  background: #c7c7c7;
}
body {
  padding: 0;
  margin: 0;
  max-width: 768px;
  margin: auto;
}
.detail-action {
  margin-top: 20px;
  padding: 0 50px;
}
</style>
